<template>
  <div
    id="order-list"
    :class="[$route.query.isMobile == 'true' ? 'order-list-mobile-app' : '']"
  >
    <b-container fluid>
      <b-row v-if="!panelMobileDisplay">
        <b-col cols="12" xl="3" id="order-header" class="panel-overflow">
          <OrderList
            :onCreateNewOrder="onCreateNewOrder"
            :onRowSelected="onRowSelected"
          />
        </b-col>
        <b-col v-if="panelOpenFlag">
          <router-view v-if="forceRefresh" />
        </b-col>

        <b-col
          v-if="!panelOpenFlag"
          cols="12"
          xl="9"
          class="item-center d-none d-xl-block"
          ><div>
            <span class="text-big text-color-secondary"
              >กรุณาเลือกรายการสั่งซื้อทางด้ายซ้าย</span
            >
          </div></b-col
        >
      </b-row>
      <b-row v-else>
        <b-col class="p-0" v-if="!panelOpenFlag">
          <OrderList
            :onCreateNewOrder="onCreateNewOrder"
            :onRowSelected="onRowSelected"
          />
        </b-col>
        <b-col class="p-0" v-if="panelOpenFlag">
          <router-view v-if="forceRefresh" />
        </b-col>
      </b-row>
    </b-container>

    <!-- modal-status-alert -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderList from "@/components/order/OrderList";
export default {
  components: {
    OrderList
  },
  data() {
    return {
      isMobile: this.$isMobile,
      panelOpenFlag: false,
      panelMobileDisplay: false,
      forceRefresh: true
    };
  },
  created: async function() {
    this.screenChange();
    if (this.$route.params.id) {
      await this.onGetOrderDetail();
    }
    window.addEventListener("resize", this.screenChange);
  },
  destroyed() {
    window.removeEventListener("resize", this.screenChange);
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      isStore: "getStoreFlag",
      isShowModalOverLay: "getIsLoading",
      mainColor: "getBrandColor"
    })
  },
  mounted() {},

  methods: {
    onGetOrderDetail: async function() {
      this.panelOpenFlag = true;
      this.handleForcrrefresh();
    },
    onRowSelected(item) {
      if (item.length > 0 && item[0].id) {
        this.$router.push(
          `/${item[0].id}?brandID=${this.$route.query.brandID}${
            this.$route.query.isMobile == "true" ? "&isMobile=true" : ""
          }`
        );

        this.panelOpenFlag = true;
        this.handleForcrrefresh();
      }
    },
    onCreateNewOrder() {
      this.panelOpenFlag = true;
      this.$router.push(
        `/0?brandID=${this.$route.query.brandID}${
          this.$route.query.isMobile == "true" ? "&isMobile=true" : ""
        }`
      );
      this.handleForcrrefresh();
    },

    screenChange() {
      if (window.innerWidth <= 1199) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.panelMobileDisplay = false;
      }
      if (this.isMobile) {
        this.panelMobileDisplay = true;
      }
    },

    handleForcrrefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#order-header {
  padding-left: 0px;
  padding-right: 0px;
}

.createButton {
  text-align: right;
}

@media only screen and (min-width: 1200px) {
  #order-header {
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

#refreshBtn:hover {
  background-color: #fca2ca;
}

#refreshBtn:active {
  background-color: #fca2ca;
}

#refreshBtn:focus {
  background-color: #fca2ca;
}

.mobile-container {
  height: calc(100vh - 320px);
  overflow: auto;
  overflow-x: hidden;
}

#createButton {
  background-color: lightgrey;
  border-color: lightgrey;
}

#order-table {
  padding-top: 20px;
}

#order-table tr:focus {
  outline: none !important;
}

#selectedStatus {
  width: 100px;
  padding-left: 5px;
  background-color: rgb(248, 248, 248);
  border-radius: 0px 50px 50px 0px;
}

#order-list-content {
  height: calc(100vh - 70px);
}

#filterBox {
  // padding-left: 30px;
  padding-right: 0px;
}

#order-detail {
  padding-left: 0px;
  padding-right: 0px;
}

#order-user-detail-col {
  padding: 0px;
}

#refreshBtn {
  background-color: lightgray;
}

#order-list {
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  // min-height: 100%;
}

.order-list-mobile-app {
  height: calc(100vh) !important;
}

@media only screen and (min-width: 1200px) {
  #order-header {
    border-right: solid 5px black;
  }
}
</style>
