<template>
  <div id="order-list-content">
    <div id="header">
      <div class="m-3 p-2 color-primary" style="border-left: 5px solid">
        <b-row>
          <b-col cols="7"
            ><span class="text-big align-middle text-black font-weight-bold"
              >รายการขายทั้งหมด</span
            ></b-col
          >
          <b-col cols="5" class="createButton">
            <b-button
              pill
              id="createButton"
              class="text-white bg-color-primary"
              @click="onCreateNewOrder"
            >
              <font-awesome-icon icon="plus" size="lg"
            /></b-button>
            <b-button
              pill
              id="refreshBtn"
              @click="onRefreshList()"
              class="ml-1 refresh-btn"
            >
              <font-awesome-icon
                icon="sync-alt"
                size="lg"
                class="color-primary"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div id="text-filter">
      <b-row class="filter-wrap">
        <b-col cols="12" xl="12" id="filterBox">
          <b-input-group>
            <b-form-input
              @keyup="onFilterOrderBySearchKeyWord()"
              placeholder="หมายเลขสั่งซื้อ, ชื่อ"
              v-model="filter.search"
              style="border-radius: 50px"
            ></b-form-input> </b-input-group
        ></b-col>
      </b-row>
      <b-row class="filter-wrap mt-2">
        <b-col class="pr-0">
          <b-form-select
            v-model="filter.statusId"
            v-on:change="onFilterOrderByStatus(filter.statusId)"
            class="selected-status"
            id="selectedStatus"
          >
            <option
              v-for="(item, index) in statusLists"
              :key="index + 'status'"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </b-form-select></b-col
        >
        <b-col class="pr-0">
          <b-form-select
            v-model="filter.salesChannel"
            v-on:change="onFilterOrderByStatus(filter.statusId)"
            class="selected-status"
            id="selectedStatus"
          >
            <option
              v-for="(item, index) in salechanelLists"
              :key="index + 'status'"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div id="order-table">
      <b-table
        striped
        hover
        :sticky-header="true"
        :items="orderLists"
        :fields="fields"
        :busy="isBusy"
        ref="selectableOrder"
        responsive
        selectable
        show-empty
        details-td-class
        select-mode="single"
        class="pointer"
        @row-selected="onRowSelected"
      >
        <template #empty>
          <div class="emptytextsearch">
            <span>ไม่พบข้อมูล</span>
          </div>
        </template>
        <template v-slot:cell(invoiceNo)="data">
          <span>{{ data.item.invoiceNo }}</span
          ><br />
          <div>
            <span
              class="text-small text-color-secondary"
              v-if="data.item.socialName"
            >
              {{ data.item.socialName }}
            </span>
            <span
              class="text-small text-color-secondary"
              v-else-if="data.item.firstname && data.item.lastname"
            >
              {{ data.item.firstname }}
              {{ data.item.lastname }}
            </span>
          </div>
        </template>

        <template v-slot:cell(grandTotal)="data">
          {{ data.item.grandTotal | numeral("0,0.00") }}
          <span class="d-block text-small text-color-secondary">
            {{ new Date(data.item.createdTime) | moment("DD MMM YYYY HH:mm") }}
          </span>
        </template>

        <template v-slot:cell(status)="data">
          <div>
            <span>
              {{ data.item.status }}
            </span>
            <span
              v-b-tooltip.hover
              title="ต้องการออกใบกำกับภาษี"
              v-if="data.item.isRequestTaxInvoice"
            >
              <font-awesome-icon class="gray" icon="exclamation-circle" />
            </span>
          </div>
        </template>
      </b-table>
      <infinite-loading
        spinner="spiral"
        @infinite="scrollMethod"
        ref="infiniteLoading"
      >
        <div slot="spinner" class="pb-4">
          <img
            src="@/assets/images/loading.svg"
            alt="loading"
            class="loading-icon"
          />
        </div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    onCreateNewOrder: Function,
    onRowSelected: Function
  },
  data() {
    return {
      filter: {
        statusId: 0,
        salesChannel: 0,
        search: "",
        take: 20,
        page: 1,
        brandId: 1
      },
      isMobile: this.$isMobile,
      forceRefresh: true,
      panelMobileDisplay: false,
      statusLists: [],
      salechanelLists: [{ id: 0, imageUrl: "", name: "ช่องทางการขาย" }],
      isBusy: true,
      orderLists: [],
      busy: false,
      loadFlag: false,
      fields: [
        {
          key: "invoiceNo",
          label: "รายละเอียดคำสั่งซื้อ"
        },
        {
          key: "grandTotal",
          label: "ราคารวม"
        },
        {
          key: "status",
          label: "สถานะ"
        }
      ],
      nameTitleArray: null
    };
  },
  created() {
    if (this.$cookies.get("tnl-session-token")) {
      if (this.brandID) {
        this.filter.brandId = this.brandID;
      } else {
        this.filter.brandId = this.$route.query.brandID;
      }
      this.$store.dispatch("setIsLoading", true);
      this.getOrderLists();
      this.getAllStatus();
      this.getAllSaleChanel();
    }
  },

  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      isStore: "getStoreFlag",
      isShowModalOverLay: "getIsLoading",
      mainColor: "getBrandColor",
      reloadListFlag: "getReloadListFlag"
    })
  },
  mounted() {},
  watch: {
    reloadListFlag: function(val) {
      if (val) {
        this.getOrderLists();
      }
    }
  },
  methods: {
    onRefreshList() {
      this.getOrderLists();
      this.$store.dispatch("setIsReload", true);
    },
    getOrderLists(scrollFlag, $state) {
      this.isBusy = true;
      if (!scrollFlag) this.filter.page = 1;
      this.$axios
        .get(this.$baseUrl + "/api/transaction", { params: this.filter })
        .then(response => {
          if (response.data.result === 1) {
            if (scrollFlag) {
              if (response.data.detail.detail.length == 0) {
                if ($state) $state.complete();
              } else {
                this.orderLists = [
                  ...this.orderLists,
                  ...response.data.detail.detail
                ];
                if ($state) $state.loaded();
              }
            } else {
              this.orderLists = response.data.detail.detail;
              if ($state) $state.loaded();
            }
            if (!this.reloadListFlag) {
              this.$store.dispatch("setIsLoading", false);
            }
            //  this.$store.dispatch("setIsLoading", false);
            this.$store.dispatch("setReloadListFlag", false);
            this.isBusy = false;
          } else {
            this.getAllBrand();
          }
        });
    },
    onFilterOrderBySearchKeyWord() {
      this.getOrderLists();
    },
    onFilterOrderByStatus() {
      this.getOrderLists();
    },
    getAllStatus() {
      this.$axios.get(this.$baseUrl + "/api/filter/status").then(response => {
        if (response.data.result === 1) {
          this.statusLists = response.data.detail;
        } else {
          alert(response.data.message);
        }
      });
    },
    getAllSaleChanel() {
      this.$axios
        .get(this.$baseUrl + "/api/socialmedia/salesChannel")
        .then(response => {
          if (response.data.result === 1) {
            this.salechanelLists = this.salechanelLists.concat(
              response.data.detail
            );
          } else {
            alert(response.data.message);
          }
        });
    },
    getAllBrand() {
      this.$axios.get(this.$baseUrl + "/api/brand").then(response => {
        if (response.data.result === 1) {
          this.$router.replace({
            path: "/",
            query: { brandID: response.data.detail[0].id }
          });
          this.filter.brandId = response.data.detail[0].id;

          this.getOrderLists();
        }
      });
    },

    scrollMethod: function($state) {
      setTimeout(() => {
        this.filter.page++;
        this.getOrderLists(true, $state);
      }, 500);
    }
    // }
  }
};
</script>

<style lang="scss" scoped>
.emptytextsearch {
  text-align: center;
  color: gray;
}
#order-header {
  padding-left: 0px;
  padding-right: 0px;
}

.createButton {
  text-align: right;
}

@media only screen and (min-width: 1200px) {
  #order-header {
    min-height: calc(100vh - 50px);
  }
}

#refreshBtn:hover {
  background-color: #fca2ca;
}

#refreshBtn:active {
  background-color: #fca2ca;
}

#refreshBtn:focus {
  background-color: #fca2ca;
}

.mobile-container {
  height: calc(100vh - 320px);
  overflow: auto;
  overflow-x: hidden;
}

#createButton {
  background-color: lightgrey;
  border-color: lightgrey;
}

#order-table {
  padding-top: 20px;
}

#order-table tr:focus {
  outline: none !important;
}

.selected-status {
  background-color: rgb(248, 248, 248);
  border-radius: 50px;
}

#order-list-content {
  height: calc(100vh - 70px);
}

#filterBox {
  // padding-left: 30px;
  padding-right: 0px;
}

#order-detail {
  padding-left: 0px;
  padding-right: 0px;
}

#order-user-detail-col {
  padding: 0px;
}

#refreshBtn {
  background-color: lightgray;
}

#order-list {
  height: calc(100vh - 50px);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  // min-height: 100%;
}

.refresh-btn {
  border-color: #8f20c6;
  background-color: white !important;
}

.filter-wrap {
  padding-left: 25px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) {
  #order-header {
    border-right: solid 5px lightgrey;
  }
}
</style>
